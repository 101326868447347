import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom' // Import hooks from react-router-dom
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import {
  CalendarMonth,
  HourglassFullOutlined,
  LocalFireDepartment,
  MenuBook,
  NotificationsActive,
  School,
  Settings,
  Videocam
} from '@mui/icons-material'
import { useGlobalState } from '../context/global'
import LegalBanner from './legal'
import LogoutModal from './modals/logout.modal'
import { StyleHelper } from './style-helper'
import introJs from 'intro.js'
import INTROJS_INFO from '../_constants/introjs-info'

const Sidebar: React.FC = () => {
  const { isSidebarOpen, setIsSidebarOpen, showIntro, setShowIntro } =
    useGlobalState()
  const navigate = useNavigate() // Hook for navigation
  const location = useLocation() // Hook to get current location
  const [showLogout, setShowLogout] = useState(false)

  useEffect(() => {
    if (showIntro) {
      _startIntro()
    }
  }, [showIntro])

  const _startIntro = () => {
    const intro = introJs()
    intro.setOptions({
      steps: INTROJS_INFO,
      overlayOpacity: 0.6
    })
    intro.oncomplete(() => setShowIntro(false))
    intro.onexit(() => setShowIntro(false))
    intro.start()
    const overlay = document.querySelector(
      '.introjs-helperLayer'
    ) as HTMLElement
    if (overlay) {
      overlay.style.backgroundColor = 'rgba(255,255,255, 0.6)' // Set your desired color here
    }
  }
  const navItems = [
    {
      text: 'Dashboard',
      icon: <HomeIcon />,
      path: '/dashboard',
      id: 'dashboard-link'
    },
    {
      text: 'Trade Alerts',
      icon: <NotificationsActive />,
      path: '/alerts',
      id: 'alerts-link'
    },
    {
      text: 'Sector Heaters',
      icon: <LocalFireDepartment />,
      path: '/sector-heaters',
      id: 'heaters-link'
    },
    {
      text: 'Buy & Hold',
      icon: <HourglassFullOutlined />,
      path: '/buy-hold',
      id: 'longterm-link'
    },
    {
      text: 'Calendar',
      icon: <CalendarMonth />,
      path: '/calendar',
      id: 'calendar-link'
    },

    {
      text: 'Daily Wrap',
      icon: <MenuBook />,
      path: '/daily-wrap',
      id: 'dailywrap-link'
    },
    // { text: 'Videos', icon: <Videocam />, path: '/videos', id: 'videos-link' },
    // { text: 'Academy', icon: <School />, path: '/academy', id: 'academy-link' },
    { text: 'Education', icon: <School />, path: '/education', id: 'education-link' },

    {
      text: 'Settings',
      icon: <Settings />,
      path: '/settings/profile',
      id: 'settings-link'
    }
  ]

  const handleNavigation = (path: string) => {
    navigate(path) // Navigate to the clicked path
  }

  return (
    <Drawer
      variant='permanent'
      onMouseOver={() => setIsSidebarOpen(true)}
      onMouseLeave={() => setIsSidebarOpen(false)}
      sx={{
        width: isSidebarOpen ? 240 : 56,
        flexShrink: 0,
        overflowX : 'hidden',
        '& .MuiDrawer-paper': {
          width: isSidebarOpen ? 240 : 56,
          boxSizing: 'border-box',
          transition: 'width 0.3s ease',
          display: 'flex',
          overflowX : 'hidden',

          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'action.hover'
        }
      }}
    >
      <Box>
        <Box sx={{ p: 1, alignSelf: 'center', textAlign: 'center' }}>
          {' '}
          {/* Add textAlign: 'center' */}
          <Box
            component='img'
            sx={{ width: 33 }}
            src={require('../assets/imgs/logo.png')}
            alt='Carnivore Logo'
          />
        </Box>
        <StyleHelper.DoubleVerticalSpace />
        <List>
          {navItems.map((item, index) => (
            <ListItem
              id={item.id}
              button
              key={index}
              onClick={() => handleNavigation(item.path)}
              sx={{
                backgroundColor: location.pathname.includes(item.path)
                  ? 'primary.main'
                  : 'inherit', // Highlight the current page
                color: location.pathname.includes(item.path)
                  ? 'primary.contrastText'
                  : 'inherit'
              }}
            >
              <ListItemIcon
                sx={{
                  color: location.pathname.includes(item.path)
                    ? 'inherit'
                    : 'action.active'
                }}
              >
                {item.icon}
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary={item.text} />}
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          width: '100%',
          padding: theme => theme.spacing(2)
        }}
      >
        {isSidebarOpen && (
          <>
            <Button variant='text' onClick={() => setShowLogout(true)}>
              Logout
            </Button>
            <LegalBanner />
          </>
        )}
        <LogoutModal
          size='md'
          open={showLogout}
          onClose={() => setShowLogout(false)}
        />
      </Box>
    </Drawer>
  )
}

export default Sidebar
